import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { CompliancyColorPipe } from '../../pipe/compliancy-color.pipe';

@Component({
  selector: 'app-gitlab-branch',
  templateUrl: './gitlab-branch.component.html',
  styleUrls: ['./gitlab-branch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GitlabBranchComponent {

  @Input()
  title: string = ''

  @Input()
  branchName: string = ''

  @Input()
  branchCompliancy: string = ''

  @Input()
  branchProjectLinkUrl: string = ''

  private _BASE_URL: string = 'https://img.shields.io/badge/'

  constructor(
    private compliancyColorPipe: CompliancyColorPipe
  ) {
  }

  public constructBranchLinkURL() {
    return this.branchProjectLinkUrl + '/-/pipelines?page=1&scope=all&ref=' + this.branchName;
  }

  public constructBranchImageURL() {
    let rightBadgePart=this.constructBadgeContent(this.branchName, this.compliancyColorPipe.transform(this.branchCompliancy));
    let finalURL = this._BASE_URL;
    return finalURL.concat(rightBadgePart);
  }

  private constructBadgeContent(text: string, color?: string) {
    return color ?
        this.adaptValue(text).concat('-', color)
      : this.adaptValue(text).concat('-', 'gray') ;
  }

  private adaptValue(text: string): string {
    return text.replaceAll('-','--').replaceAll('_','__')
  }
}
